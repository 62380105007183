import React from "react"
import "./Hero.scss"
import PrimaryAnchor from "../../PrimaryAnchor/PrimaryAnchor"
import { useStaticQuery } from "gatsby"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel"

import { Link } from "gatsby"
import { element } from "prop-types"

export default function Hero() {
  const heroQuery = useStaticQuery(graphql`
    query {
      allStrapiSlide {
        nodes {
          id
          title
          Description
          call_to_action
          Image {
            publicURL
            id
          }
        }
      }
    }
  `)
  const hero = heroQuery.allStrapiSlide.nodes

  return (
    <div className="carrousel_wrapper">
      <Carousel showThumbs={false} showStatus={false} autoPlay>
        {hero.map((slide, index) => {
          return (
            <Link to={slide.call_to_action} style={{ width: "100%" }}>
              <img src={slide.Image.publicURL}></img>
            </Link>
          )
        })}
      </Carousel>
      <svg
        style={{ position: "absolute", bottom: -1 }}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 300"
      >
        <path
          fill="#e8e8e8"
          fill-opacity="1"
          d="M0,288L1440,160L1440,320L0,320Z"
        ></path>
      </svg>
    </div>
  )
}
