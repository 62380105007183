import React from "react"
import "./Product.scss"
import { Link } from "gatsby"

export default function Product({ product, ...props }) {
  console.log(product)
  return (
    <div className="productBox">
      <img
        style={{ height: 50 }}
        src={product.node.ProductIcon.publicURL}
      ></img>
      <p
        style={{
          fontFamily: "Lato",
          fontSize: 14,
          textAlign: "center",
          marginTop: 10,
          fontWeight: 600,
        }}
      >
        {product.node.header}
      </p>
      <p
        style={{
          fontSize: 13,
          textAlign: "center",
          marginTop: 10,
        }}
      >
        {product.node.description}
      </p>
      <Link
        className="learnmoreLink has-text-centered"
        to={`/products/${product.node.slug}`}
      >
        {" "}
        learn more
      </Link>
    </div>
  )
}
