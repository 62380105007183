import React, { useCallback } from "react"
import "./Featured.scss"
import Feature from "./components/Feature"
import { useStaticQuery } from "gatsby"

export default function Featured() {
  const featureSuiteQuery = useStaticQuery(graphql`
    query MyQuery {
      allStrapiFeaturesuite {
        edges {
          node {
            Description
            title
            id
          }
        }
      }
    }
  `)
  const data = featureSuiteQuery.allStrapiFeaturesuite.edges

  const onScroll = () => {
    let elementoffset = document.getElementById("featurebox").offsetTop
    let features = document.getElementsByClassName("FeatureBox")
    if (window.pageYOffset > elementoffset) {
      Object.keys(features).forEach(element => {
        features[element].classList.add("animateUp")
      })
    }
  }

  React.useEffect(() => {
    window.addEventListener("scroll", onScroll)
    return () => {
      window.removeEventListener("scroll", onScroll)
    }
  }, [])

  return (
    <div style={{ position: "relative" }}>
      <div className="skewed_container Grid">
        <div className="Featured_title ">
          <h1>Featured Suite</h1>
        </div>

        <div className="Feature1" id="featurebox">
          <Feature feature={data[0].node} />
        </div>
        <div className="Feature2">
          <Feature feature={data[1].node} />
        </div>
        <div className="Feature3">
          <Feature feature={data[0].node} />
        </div>
      </div>
      <svg
        style={{ position: "absolute", bottom: 0 }}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
      >
        <path
          fill="#ffffff"
          fill-opacity="1"
          d="M0,288L1440,160L1440,320L0,320Z"
        ></path>
      </svg>
    </div>
  )
}
