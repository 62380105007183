import React from "react"
import "./PlatformFeature.scss"

export default function PlatformFeature({ feature, ...props }) {
  return (
    <div className="platformfeaturewrapper">
      <h2 className="platformfeatureheader has-text-centered">
        {feature.node.header}
      </h2>
      <div style={{ height: 120 }}>
        <img
          style={{ height: "100%", width: "100%" }}
          src={feature.node.icon.publicURL}
        />
      </div>
      <p className="platformFeatureDescription has-text-centered">
        {feature.node.description}
      </p>
    </div>
  )
}
