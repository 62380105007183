import React from "react"
import "./Products.scss"
import ProductTile from "../../ProductTile/ProductTile"
import PrimaryAnchor from "../../PrimaryAnchor/PrimaryAnchor"
import { useStaticQuery, graphql } from "gatsby"
import Product from "./components/Product/Product"

export default function Products() {
  const productsQuery = useStaticQuery(graphql`
    query {
      allStrapiNewproduct {
        edges {
          node {
            header
            id
            description
            slug
            ProductIcon {
              publicURL
            }
          }
        }
      }
      allStrapiProductsection {
        edges {
          node {
            Title
            Description
            Platform_Title
            Platform_Description
            Product_Section_Image {
              publicURL
            }
            Read_More_Text
          }
        }
      }
    }
  `)

  const products = productsQuery.allStrapiNewproduct.edges
  const productsInformation =
    productsQuery.allStrapiProductsection.edges[1].node

  console.log(products)
  return (
    <div>
      <div id="products" className="ProductBanner">
        <div className="ProductTitleBox has-text-centered">
          <h1 className="ProductTitle">{productsInformation.Title}</h1>
          <h2 className="subtitle has-text-centered">
            {productsInformation.Description}
          </h2>
        </div>
      </div>
      <div
        className="columns ProductsRow is-multiline"
        style={{ marginTop: 40, width: "100%" }}
      >
        {products.map((product, index) => {
          return (
            <div className="column is-one-fifth-widescreen is-two-fifth-desktop is-half-tablet  is-full-mobile">
              <Product product={product}></Product>
            </div>
          )
        })}
      </div>
    </div>
  )
}
