import React from "react"
import { useStaticQuery } from "gatsby"
import "./Solution.scss"

export default function Solutions() {
  const SolutionsQuery = useStaticQuery(graphql`
    query {
      allStrapiSolution {
        edges {
          node {
            content
            header
            id
            img {
              publicURL
            }
          }
        }
      }

      allStrapiSolutionsection {
        edges {
          node {
            content
            header
          }
        }
      }
    }
  `)
  const Solutions = SolutionsQuery.allStrapiSolution.edges

  const SolutionSection = SolutionsQuery.allStrapiSolutionsection.edges[0].node

  return (
    <div
      id="solutions"
      className="container"
      style={{ marginTop: 110, maxWidth: 1240 }}
    >
      <h1 className="has-text-centered">{SolutionSection.header}</h1>
      <div
        className="solutionHeaderDescription has-text-centered"
        style={{ marginTop: 10 }}
      >
        <p className="SolutionDescription has-text-centered">
          {" "}
          {SolutionSection.content}
        </p>
      </div>

      <div
        className="columns is-multiline is-8"
        style={{ marginTop: 30, padding: 32 }}
      >
        {Solutions.map(solution => {
          return (
            <div className="column is-one-third-fullhd is-one-third-widescreen is-half-tablet solutions ">
              <div className="IconBox ">
                <img
                  style={{ height: "100%" }}
                  src={solution.node.img.publicURL}
                />
              </div>
              <h2 className="solutionHeaderText">{solution.node.header}</h2>
              <p style={{ fontSize: 14 }}>{solution.node.content}</p>
            </div>
          )
        })}
      </div>
    </div>
  )
}
