import React from "react"
import "./Platform.scss"
import { useStaticQuery, graphql } from "gatsby"
import PlatformFeature from "./components/PlatformFeature"

export default function Platform() {
  const platformQuery = useStaticQuery(graphql`
    query {
      allStrapiPlatforminformationsection {
        edges {
          node {
            img {
              publicURL
            }
            description
            header
          }
        }
      }
      allStrapiNewplatformfeature {
        edges {
          node {
            header
            description
            icon {
              publicURL
            }
          }
        }
      }
    }
  `)

  const PlatformInformation =
    platformQuery.allStrapiPlatforminformationsection.edges[0].node

  const PlatformFeatures = platformQuery.allStrapiNewplatformfeature.edges

  return (
    <div style={{ marginBottom: 20 }}>
      <div className="container" style={{ marginTop: 50, maxWidth: 1240 }}>
        <h1 className="has-text-centered">Platform Features</h1>
        <div className="PlatformContainer">
          <div className="PlatformInfo">
            <h2 style={{ fontSize: "1.2rem", fontWeight: 800 }}>
              {PlatformInformation.header}{" "}
            </h2>
            <p style={{ marginTop: 16 }}>{PlatformInformation.description}</p>
          </div>
          <div className="platformImage">
            <img
              style={{ height: "100%", width: "100%" }}
              src={PlatformInformation.img.publicURL}
            />
          </div>
        </div>
      </div>
      <div className="columns is-8" style={{ padding: 10 }}>
        {PlatformFeatures.map((feature, index) => {
          return (
            <div className="column is-4">
              <PlatformFeature feature={feature}></PlatformFeature>
            </div>
          )
        })}
      </div>
    </div>
  )
}
