import React from "react"
import "../Featured.scss"

export default function Feature({ feature, ...props }) {
  return (
    <div className="FeatureBox">
      <div className="FeatureContentBox">
        <h1 className="headerFeature">{feature.title}</h1>
        <p className="headerFeaturedescription">{feature.Description}</p>
      </div>
    </div>
  )
}
